<template>
	<div class="row text-center" id="code-entry">
		<!--		<input class="form-control col mx-1" tabindex="1" :data-limit="1" v-on:keypress="validateInput"-->
		<!--			:data-valid="['p','n']">-->
		<input v-model="coord.x" class="form-control col mx-1" tabindex="2" :data-limit="3" v-on:keydown="validateInput" v-on:keyup="validateInput" v-on:click="coord = {}"
			:data-valid="['0','1','2','3','4','5','6','7','8','9', 0,1,2,3,4,5,6,7,8,9]">
		<!--		<input class="form-control col mx-1" tabindex="3" :data-limit="1" v-on:keypress="validateInput"-->
		<!--			:data-valid="['p','n']">-->
		<input v-model="coord.y" class="form-control col mx-1" tabindex="4" :data-limit="3" v-on:keydown="validateInput" v-on:keyup="validateInput" v-on:click="coord = {}"
			:data-valid="['0','1','2','3','4','5','6','7','8','9', 0,1,2,3,4,5,6,7,8,9]">
		<button class="btn btn-primary btn-sm" tabindex="5" v-on:click="go" v-on:focusIn="go">GOTO</button>
	</div>
</template>

<script>
	export default {
		name: "LandInput",
		data() {
			return {
				coord: {
					x: 'X',
					y: 'Y',
				}
			}
		},
		methods: {
			validateInput: function (e) {
				if (!this.validateKeyPress(e))
					return
				
				console.log(e)
				console.log("e", e.target.dataset.limit, "l:", e.target.value)
				if (e.target.value.length >= e.target.dataset.limit) {
					console.log("Sib: ", e.target.nextSibling)
					e.target.nextSibling.focus()
					e.preventDefault();
				}
			},
			
			
			validateKeyPress: function (e) {
				if (e.target.dataset.valid) {
					const tv = e.target.value.toLowerCase();
					for (let i = 0; i < e.target.value.length; i++) {
						if (e.target.dataset.valid.indexOf(tv[i]) === -1) {
							e.target.value = e.target.value.substr(0, i)
							e.preventDefault()
							return false
						}
					}
				}
				
				return true
			},
			
			go: function () {
				console.log("Searching: ", this.coord);
				this.$emit("go", this.coord)
			},
		}
	}
</script>

<style scoped>
	input.form-control {
		border: 1px solid white;
		border-radius: 8px;
		background: #0E0E0F;
		color: white;
		font-weight: bold;
		letter-spacing: 10px;
	}
</style>