<template>
	<div class="container-fluid index p-5">
		<div class="row">
			<div class="col-8">
				<h3 class="text-left">Land<small>
					<button class="btn-none btn-sm">ALL</button>
				</small></h3>
			</div>
			<div class="col-4 text-right">
				<LandInput @go="search"></LandInput>
			</div>
		</div>
		<div class="row my-3 text-center" v-if="searchKey !== ''">
			<div class="col-12">
				<h5 class="text-center">Filtering {{ searchKey.x }},{{searchKey.y}}</h5>
			</div>
		</div>
		<div class="row text-center">
			<CommunityItemCollection :items="items"></CommunityItemCollection>
		</div>
	</div>
</template>

<script>
	import CommunityItemCollection from "../../components/shop/CommunityItemCollection";
	import LandInput from "../../components/land/LandInput";
	import { Connection, programs } from '@metaplex/js';
	
	export default {
		name: "ShopIndex",
		components: {LandInput, CommunityItemCollection},
		data() {
			return {
				searchKey: '',
				items: []
			}
		},
		methods: {
			
			getLandForSale: function() {
			
			},
			
			getActiveAuctions: function() {
				const connection = new Connection('devnet');
				const tokenPublicKey = 'Gz3vYbpsB2agTsAwedtvtTkQ1CG9vsioqLW3r9ecNpvZ';
				
				programs.metadata.Metadata.load(connection, tokenPublicKey).then(resp => console.log(resp)).catch(e => alert(e));
			},
			
			getBalance: function() {
				const connection = new Connection('devnet');
				const tokenPublicKey = 'Gz3vYbpsB2agTsAwedtvtTkQ1CG9vsioqLW3r9ecNpvZ';
				connection.getBalance(tokenPublicKey).then(resp => console.log(resp))
			},
			
			spawn: function () {
				for (let i = 0; i < 18; i++) {
					
					const item = {
						id: i,
						name: "Item " + i,
						description: "This is a description for item " + i,
						price: (i * 10 + 1) * (Math.round(Math.random() * 100)),
						creator: {
							id: i,
							name: "Creator " + i,
							wallet_addr: "0x" + i.toString(16),
							avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						owner: {
							id: i,
							name: "Owner " + i,
							wallet_addr: "0x" + i.toString(16),
							avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						media: {
							thumbnail_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							image_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							video_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							object_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						metadata: {},
						tags: [i, i + 1, i + 2],
						analytics: {
							views: i * 10,
						},
						history: [
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "purchase",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
						],
					}
					
					this.items.push(item)
				}
			},
			search: function (landKey) {
				this.searchKey = landKey
				console.log(landKey);
			}
		},
		mounted() {
			this.spawn();
		}
	}
</script>

<style scoped>
	h3 {
		font-family: "Prime-Light", sans-serif;
		color: #0a9fb9;
		font-size: 1.9rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 3vh;
	}
	
	h5 {
		color: white;
	}
</style>