<template>
	<div class="search">
		<div class="search__input">
			<input class="searchbox" type="text" v-model="search" @keyup.enter="searchFor" placeholder="Search...">
		</div>
		<div class="search__button">
			<button class="btn-search" @click="searchFor"><i class="fa fa-search"></i></button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SearchBar",
		data() {
			return {
				search: ""
			}
		},
		methods: {
			searchFor() {
				console.log("Searching", this.search);
				this.$emit("search", this.search);
			},
		}
	}
</script>

<style scoped>
	.search {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}
	
	.searchbox {
		border-radius: 4px;
	}
	
	.search__input {
		flex: 1;
	}
	
	.search__button {
		border-radius: 3px;
		margin-left: 10px;
		height: 44px;
		text-align: center;
	}
	
	.btn-search {
		text-align: center;
		height: 100%;
		max-height: 100%;
		border-radius: 3px;
		background: none;
		padding: 0 0 0 5px;
	}
	
	.fa {
		font-size: 1rem;
	}
</style>