<template>
	<div class="container-fluid index mt-3">
		<div class="row text-center" id="game-container">
			<div class="col-12 mb-3">
				<h1 class="text-left wow fadeInDown">{{ item.name }}</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-5">
				<div class="media-container">
					<img id="media" class="img-fluid" :src="item.media.image_src" alt="media">
				</div>
			</div>
			<div class="col-4">
				<div class="detail-container">
					<div class="row">
						<div class="col-12 wow fadeIn"><h1>{{ item.name }}</h1></div>
						<div class="col-12 wow fadeIn">
							<div class="owner-avatar"><img class="img-thumbnail" :src="item.owner.avatar"></div>
							<div class="owner-name">{{ item.owner.name }}</div>
						</div>
						
						<div class="col-12 mt-5 wow fadeIn" data-animation-delay="0.3s"><p>{{ item.description }}</p></div>
					</div>
					
					<div class="row">
						<div class="col-12">
						
						</div>
					</div>
					<div class="text-center d-footer row wow fadeIn">
						<div class="col-3">
							<h1>${{ nFormatter(item.price.fiat, 2) }}</h1>
						</div>
						<div class="col-6">
							<button class="btn btn-primary btn-block">BUY NOW</button>
						</div>
						<div class="col-3">
							<h1><i class="fab fa-ethereum text-white"></i> {{ nFormatter(item.price.eth, 2) }}</h1>
						</div>
					</div>
				</div>
			</div>
			
			<div class="col-3">
				<div id="history" class="history-list">
					<div class="history-entry px-3 mb-5 wow fadeInUp" v-for="(hist,idx) in item.history" :key="idx">
						<div class="row">
							<div class="col">
								<h2 class="action">{{ hist.action }}</h2>
								<i class="action-time">3 Minutes Ago</i>
							</div>
							<div class="col text-right">
								<div class="parties text-center">
									<h3>{{ hist.wallet_addr }}</h3>
									<h4><i class="fa fa-arrow-up"></i></h4>
									<h3>{{ hist.wallet_addr }}</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="row mt-2">
			<div class="col-9">
				<div class="property-container">
					<h2 class="mb-3">Properties</h2>
					
					<div class="row">
						<div class="col-3 mb-3" v-for="(meta,idx) in item.metadata" :key="idx">
							<div class="property text-center wow fadeIn" data-animation-delay="0.5s">
								<h1>{{ meta.value }}</h1>
								<h3>{{ meta.key }}</h3>
							</div>
						</div>
					</div>
					
					
					<!--					<pre><code>{{ item }}</code></pre>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MarketIndex",
		props: {
			item: {
				type: Object,
				// required: true,
				default: function () {
					let i = this.$route.params.id;
					return {
						id: i,
						name: "CryptoPunk #9337",
						description: "This is a description for item.",
						price: {
							fiat: 229292,
							eth: 54.80,
							sol: 1208.92,
						},
						creator: {
							id: i,
							name: "Creator " + i,
							wallet_addr: "0x" + i.toString(16),
							avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						owner: {
							id: i,
							name: "PJLuckyTrader",
							wallet_addr: "0xdf612c887e88f8e87ac0d2aa04ffd91f0556cfc0",
							avatar: "https://lh3.googleusercontent.com/JPJFg7AlJwL1CE8zJX6quHds9DtJg0VZmt-9qtin95rSAxV2IJKqOmoH3hB1kiJjByBqRmL_EeQBGr1uYGtsnaM4fTdwi2o32y5eNQ=s0",
						},
						media: {
							thumbnail_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							image_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							video_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							object_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						metadata: [
							{key: "Accessory", value: "Earring"},
							{key: "Accessory", value: "Luxurious Beard"},
							{key: "Accessory", value: "Mohawk"},
							{key: "Type", value: "Male"},
						],
						tags: [i, i + 1, i + 2],
						analytics: {
							views: i * 10,
						},
						history: [
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "purchase",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "purchase",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "purchase",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "transfer",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "mint",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
						],
					}
					
				}
			}
		},
		data() {
			return {
				//
			}
		},
		methods: {
			/**
			 * Formats numbers nicely with K,M etc with precision < 1k
			 * @param num
			 * @param digits
			 * @returns {string|string}
			 */
			nFormatter: function (num, digits) {
				const lookup = [
					{value: 1, symbol: ""},
					{value: 1e3, symbol: "K"},
					{value: 1e6, symbol: "M"},
					{value: 1e9, symbol: "G"},
					{value: 1e12, symbol: "T"},
					{value: 1e15, symbol: "P"},
					{value: 1e18, symbol: "E"}
				];
				const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
				var item = lookup.slice().reverse().find(function (item) {
					return num >= item.value;
				});
				
				if (item.value === 1) {
					return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
				}
				
				return item ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol : "0";
			}
		},
		mounted() {
			const el = document.getElementById("history")
			const media = document.getElementById("media")
			console.log("Setting style", media.clientHeight);
			el.style.maxHeight = `${media.clientHeight}px`;
			el.height = `${media.clientHeight}px`;
		}
	}
</script>

<style scoped>
	
	h1, h2, h3, h4 {
		color: white;
	}
	
	h4 {
		font-size: ;
	}
	
	h1 {
		font-size: 1.8rem;
	}
	
	.detail-container, .property-container, .history-container, .history-entry, .property {
		color: white;
		background: #0E0E0F;
		border-radius: 8px;
		padding: 2%;
		
		transition: all 0.3s ease-in-out;
	}
	
	.media-container, .detail-container {
		min-height: 100%;
	}
	
	.detail-container {
		position: relative;
	}
	
	.detail-container .d-footer {
		position: absolute;
		bottom: 4%;
		width: 100%;
	}
	
	.img-fluid {
		width: 100%;
		border-radius: 8px;
	}
	
	.img-thumbnail {
		border: none;
		background: none;
		max-height: 40px;
		border-radius: 8px;
	}
	
	.history-list:first-child .history-entry {
		box-shadow: 1px 1px 8px #26b6d4;
	}
	
	.history-list {
		padding: 2%;
	}
	
	
	.action {
		text-transform: uppercase;
	}
	
	.owner-avatar {
		display: inline-block;
	}
	
	.owner-name {
		display: inline-block;
		line-height: 40px;
		vertical-align: top;
	}
	
	pre {
		color: white;
	}
	
	.action-time {
		font-style: italic;
		font-size: 0.8rem;
		color: grey;
	}
	
	.history-list {
		overflow-y: scroll;
	}
	
	/* Hide scrollbar for Chrome, Safari and Opera */
	.history-list::-webkit-scrollbar {
		display: none;
	}
	
	/* Hide scrollbar for IE, Edge and Firefox */
	.history-list {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	
	
	.property {
		color: #26b6d4;
		background: #1e1e1f;
		transition: all 0.3s ease-in-out;
	}
	
	.property:hover {
		transform: scale(1.1);
		box-shadow: 1px 1px 8px #26b6d4;
	}
</style>