import {api, apiHelper} from "./api";

export class Item {
	prefix() {
		return "items"
	}

	index(cb, page = 0, limit = 50, sort = 'uid', sortOrder = 'desc') {
		return api.get(`${this.prefix()}?${apiHelper.buildPaginationQuery(page, limit, sort, sortOrder)}`)
	}

	show(uid) {
		const cid = '0x0'
		return api.get(`${this.prefix()}/contracts/${cid}/items/${uid}`)
	}

	create(obj) {
		return api.post(`${this.prefix()}`, obj)
	}

	update(uid, obj) {
		return api.put(`${this.prefix()}/${uid}`, obj)
	}


	import(obj) {
		return api.post(`importer/requests`, obj)
	}

	updateLogo(id, formData) {
		return api.post(`${this.prefix()}/${id}/logo`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
	}


	updateFile(id, formData) {
		return api.post(`${this.prefix()}/${id}/file`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
	}
}

export default new Item()