<template>
	<router-link :to="`/marketplace/assets/${this.item.id}`" class="item-card">
		<div class="item">
			<div class="media-container">
				<img class="img-fluid" :src="item.media.image_src">
			</div>
			<div class="detail-container">
				<div class="row">
					<div class="col-7">
						<h3>{{ item.name }}</h3>
						<h4>{{ item.creator.name }}</h4>
					</div>
					<div class="col-5 text-right">
						<h2 class="price">{{ item.price > 0 ? `$${item.price}` : "FREE" }}</h2>
					</div>
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		name: "ItemCard",
		props: {
			item: {
				type: Object,
				required: true
			}
		}
	}
</script>

<style scoped>
	.item-card {
		min-height: 10vh;
	}
	
	.item {
		color: white;
		background: #0E0E0F;
		border-radius: 8px;
		box-shadow: black 0 4px 7px;
		transition: all 0.3s ease-in-out;
	}
	
	.detail-container {
		padding: 1rem;
	}
	
	h2, h3, h4 {
		text-align: left;
		color: white;
	}
	
	h2 {
		text-align: center;
		font-size: 2rem;
	}
	
	h2.price {
		text-align: right;
	}
	
	.item:hover {
		transform: scale(1.05);
		box-shadow: black 0 7px 9px;
	}
	
	.img-fluid {
		width: 100%;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
</style>