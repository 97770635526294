<template>
	<div class="container-fluid index p-5">
		<div class="shop-nav row text-center">
			<div class="col">
				<router-link to="/shop/land" class="btn btn-primary btn-sm btn-table">Land</router-link>
			</div>
			<div class="col">
				<router-link to="/shop/buildings" class="btn btn-primary btn-sm btn-table">Buildings</router-link>
			</div>
			<div class="col">
				<router-link to="/shop/sponsored" class="btn btn-primary btn-sm btn-table">Sponsored</router-link>
			</div>
			<div class="col">
				<router-link to="/shop/free" class="btn btn-primary btn-sm btn-table">Free</router-link>
			</div>
		</div>
		<div class="row text-center mt-5" id="game-container">
			<div class="col-lg-6 col-sm-12">
				<div class="row">
					<div class="col-12">
						<h3 class="text-left wow fadeInDown">Free<small>
							<!--							<button class="btn-none btn-sm">ALL</button>-->
						</small></h3>
					</div>
					<ShopItemCollection :items="free"></ShopItemCollection>
				</div>
			</div>
			<div class="col-lg-6 col-sm-12">
				<div class="row">
					<div class="col-12">
						<h3 class="text-left wow fadeInDown">Sponsored<small>
							<!--							<button class="btn-none btn-sm">ALL</button>-->
						</small></h3>
					</div>
					<ShopItemCollection :items="trending"></ShopItemCollection>
				</div>
			</div>
		</div>
		<div class="row text-center mt-5">
			<div class="col-12">
				<h3 class="text-left">Community<small>
										<button class="btn-none btn-sm">ALL</button>
				</small></h3>
			</div>
			<CommunityItemCollection :items="community"></CommunityItemCollection>
		</div>
	</div>
</template>

<script>
	import ShopItemCollection from "../../components/shop/ShopItemCollection";
	import CommunityItemCollection from "../../components/shop/CommunityItemCollection";
	
	export default {
		name: "ShopIndex",
		components: {CommunityItemCollection, ShopItemCollection},
		data() {
			return {
				free: [],
				trending: [],
				community: [],
			}
		},
		methods: {
			spawn: function () {
				for (let i = 0; i < 18; i++) {
					
					const item = {
						id: i,
						name: "Item " + i,
						description: "This is a description for item " + i,
						price: (i * 10 + 1) * (Math.round(Math.random() * 100)),
						creator: {
							id: i,
							name: "Creator " + i,
							wallet_addr: "0x" + i.toString(16),
							avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						owner: {
							id: i,
							name: "Owner " + i,
							wallet_addr: "0x" + i.toString(16),
							avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						media: {
							thumbnail_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							image_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							video_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
							object_src: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
						},
						metadata: {},
						tags: [i, i + 1, i + 2],
						analytics: {
							views: i * 10,
						},
						history: [
							{
								id: i,
								name: "Owner " + i,
								avatar: "https://lh3.googleusercontent.com/wl09kAQ8Zr_9rMmJM6hquCMWctYAgiQIOCnCvuHkabkaUKoC2hHq_qR2_5Re_Kq9j5ayjkqUnI19sHjKwvD8j8lyfZcUP_Meq8Q9=w600",
								action: "purchase",
								wallet_addr: "0x" + i.toString(16),
								timestamp: new Date().getTime(),
							},
						],
					}
					
					this.community.push(item)
					if (i < 6) {
						this.trending.push(item)
						
						let f = Object.assign({}, item)
						f.price = 0;
						this.free.push(f)
					}
				}
			}
		},
		mounted() {
			this.spawn();
		}
	}
</script>

<style scoped>
	h3 {
		font-family: "Prime-Light", sans-serif;
		color: #0a9fb9;
		font-size: 1.9rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 3vh;
	}
</style>