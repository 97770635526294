<template>
	<div class="collection">
		<div class="row mx-2">
			<ItemCard class="col-lg-3 col-md-6 col-sm-6 mb-4 item-card" v-for="item in items" :key="item.id" :item="item"></ItemCard>
		</div>
	</div>
</template>

<script>
	import ItemCard from "../marketplace/ItemCard";
	
	export default {
		name: "ItemCollection",
		components: {ItemCard},
		props: {
			collection: {
				type: Object
			},
			items: {
				type: Array,
				required: true
			}
		}
	}
</script>

<style scoped>
	.item-card {
		min-height: 10vh;
	}
</style>